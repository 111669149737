import config from "./config";
// 存储localStorage
export const setStore = (name, content) => {
  if (!name) return;
  localStorage.setItem(name, JSON.stringify(content));
};

// 获取localStorage
export const getStore = name => {
  if (!name) return;
  return JSON.parse(localStorage.getItem(name));
};

export function removeSpace(value) {
  return value.replace(/\s+/g, "");
}

export function formValidate(val, type) {
  const phoneReg = /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/;
  const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
  if (val === "") {
    return false;
  } else {
    // 非空验证
    if (type === "require") {
      return !!removeSpace(val);
    }
    if (type === "phone") {
      return phoneReg.test(val);
    }
    if (type === "email") {
      return emailReg.test(val);
    }
  }
}

// 获取url参数
export function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}

// 数组去重
export function dedupe(array) {
  return Array.from(new Set(array));
}

export const ModalHelper = (bodyCls => {
  let scrollTop;
  return {
    afterOpen: function () {
      scrollTop = document.scrollingElement.scrollTop;
      document.body.classList.add(bodyCls);
      document.body.style.top = -scrollTop + "px";
    },
    beforeClose: function () {
      document.body.classList.remove(bodyCls);
      // scrollTop lost after set position:fixed, restore it back.
      document.scrollingElement.scrollTop = scrollTop;
    }
  };
})("dialog-open");
// 防抖
export function debounce(cb, duration = 500) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      this[cb].apply(this, args);
    }, duration);
  };
}
// 节流
function throttle(func, wait) {
  let timeout = null;
  let firstCall = true; // 标记是否为首次调用

  return function () {
    const context = this;
    const args = arguments;

    if (firstCall) {
      // 如果是首次调用，则直接执行函数
      func.apply(context, args);
      firstCall = false;
    } else if (!timeout) {
      // 如果不是首次调用且没有正在等待的计时器，则设置计时器
      timeout = setTimeout(function () {
        func.apply(context, args);
        timeout = null; // 清除计时器
      }, wait);
    }
  };
}
export function handleScroll(event) {
  const windowHeight = window.innerHeight;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const hasVerticalScrollbar =
    document.documentElement.scrollHeight >
    document.documentElement.clientHeight;

  // 判断是否触底
  const isBottomReached = () => {
    const bottomOffset = 20; // 距离底部多少像素时认为触底
    return (
      documentHeight - (scrollTop + windowHeight) <= bottomOffset &&
      hasVerticalScrollbar
    );
  };

  return isBottomReached();
}
export function getPointsImgUrl(picturePath) {
  if (!picturePath) {
    return
  }
  const firstImg = picturePath.split(";")[0]
  return `${config.imgUrl}${firstImg}`
}