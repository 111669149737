export default [
    {
      path: '/pointsMall',
      name: 'pointsMall',
      component: () => import('@/views/pointsMall/index.vue'),
    },
    {
      path: '/pointsMall/exchangeRecord',
      name: 'exchangeRecord',
      component: () => import('@/views/pointsMall/exchangeRecord.vue'),
    },
    {
      path: '/pointsMall/pointsRecord',
      name: 'pointsRecord',
      component: () => import('@/views/pointsMall/pointsRecord.vue'),
    },
    {
      path: '/pointsMall/detail/:id',
      name: 'detail',
      component: () => import('@/views/pointsMall/goodsDetail.vue'),
    },
    {
      path: '/pointsMall/confirm',
      name: 'confirm',
      component: () => import('@/views/pointsMall/confirm.vue'),
    },
]