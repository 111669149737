import axios from "axios";
import {getToken,removeToken } from "@/utils/token";
import router from "../router";
import { Toast } from "vant";
let SUCCESSCODE=1200
const service = axios.create({
	withCredentials: true,
});
const white=['wxOauthCallback']
const dispatchAction = (dispatch, action) => {
	dispatch(action);
  };
service.interceptors.request.use(
	config => {
		if (config.url.includes('authapi')&&!getToken()) {
	    location.href='/login'+`?redirect=${router.currentRoute.value.fullPath}`
			return Promise.reject({message:'请先登录',resultID:9999});
		}
		
		if(getToken()){
			config.headers['Authorization'] = getToken();
		}
		if(!config.headers['LangEnv']){
			config.headers['LangEnv']=localStorage.getItem('langEnv')
		}
		if(config.headers.appLangEnv){
			// localStorage.setItem('langEnv',config.headers.appLangEnv)
			config.headers['LangEnv']=config.headers.appLangEnv

		}
	
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);
service.interceptors.response.use(
	response => {

		
		const { data } = response;

		const notToast=response.config.url.split('/')
		
		if(data.resultID!==1200 && !data.isSuccess && !white.includes(notToast[notToast.length-1])){
			Toast({
				 message: data.message,
				 forbidClick:true
				})
		}
		if(data.resultID===1323 || data.resultID===1300){
			removeToken();
			location.href='/login'+`?redirect=${router.currentRoute.value.fullPath}`
		}
		return data;
	},
	error => {

		
		if (error) {
			const { response } = error;
			if (response && response.resultID !== SUCCESSCODE) {

				return Promise.reject(response);
			} 
		}

		if (
			error.resultID === 'ECONNABORTED' ||
			error.message === 'Network Error' ||
			error.message.includes('timeout')
		) {
			console.error('err' + error); // for debug
		} else {
			console.error('err' + error); // for debug

		}
		return Promise.reject(error);
	}
);
export default service;