import  {createI18n}  from 'vue-i18n'
import zh from './zh.json'
import en from './en.json'

// 获取浏览器界面语言，默认语言
// https://developer.mozilla.org/zh-CN/docs/Web/API/Navigator/language
let currentLanguage = 'en'
console.log(currentLanguage);

// 如果本地缓存记录了语言环境，则使用本地缓存
let lsLocale = localStorage.getItem('langEnv') || ''
const queryString = window.location.search;

// 去掉开头的 '?' 符号并分割成数组
const params = new URLSearchParams(queryString);

// 获取特定参数的值
const langEnv = params.get('langEnv')
if (langEnv) {
  localStorage.setItem('langEnv', langEnv)
  lsLocale= langEnv
} 
if (lsLocale) {
  currentLanguage = JSON.parse(lsLocale) == '3' ? 'zh' : 'en'
}

export default  createI18n({
  locale: currentLanguage,
  legacy: false, // 修复组件引入i18n时vite脚手架报错的问题
  globalInjection: true, // 全局注册 $t
  messages: {
    zh,
    en
  }
})

export const langs = [
  { key: 'zh', title: '中文' },
  { key: 'en', title: 'English' }
]
